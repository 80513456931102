import Loadable from 'components/Loadable';
import { lazy } from 'react';

const Categories = Loadable(lazy(() => import('./Categories')));
const CreateCategory = Loadable(lazy(() => import('./_pages/CreateCategory')));
const CategoryDetails = Loadable(lazy(() => import('./_pages/Details')));
const UpdateCategory = Loadable(lazy(() => import('./_pages/UpdateCategory')));

export const categoriesRoutes = [
  { path: '/categories', element: <Categories /> },
  { path: '/categories/create', element: <CreateCategory /> },
  { path: '/categories/details/:categoryId', element: <CategoryDetails /> },
  { path: '/categories/update/:id', element: <UpdateCategory /> },
];
