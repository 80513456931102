import Loadable from 'components/Loadable';
import { lazy } from 'react';



const Flavors = Loadable(lazy(() => import('./Flavors')));
const Country = Loadable(lazy(() => import('./Countries')));
const Sizes = Loadable(lazy(() => import('./Sizes')));
const DataMenu = Loadable(lazy(() => import('./index')));
const Locations = Loadable(lazy(() => import('./Locations')));
const Features = Loadable(lazy(() => import('./Features')));
const Colors = Loadable(lazy(() => import('./Colors')));
const ProductTypes = Loadable(lazy(() => import('./ProductType')));

export const dataRoutes = [
  { path: '/data', element: <DataMenu /> },
  { path: '/data/flavors', element: <Flavors /> },
  { path: '/data/countries', element: <Country /> },
  { path: '/data/sizes', element: <Sizes /> },
  { path: '/data/locations', element: <Locations /> },
  { path: '/data/features', element: <Features /> },
  { path: '/data/colors', element: <Colors /> },
  { path: '/data/product-types', element: <ProductTypes /> },

];

export default dataRoutes;
