import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'

import { useNavigate } from 'react-router-dom'


import { apiURL } from 'common';
import http from 'common/http';

import Page from 'components/Page';
import PageEditor from '../_components/PageEditor';

const CreateCustomPage = () => {
    const navigate = useNavigate()

    const handleSave = async (postData, setLoader) => {
       try {
        const url = `${apiURL}/admin/page`;
        setLoader(true)
        const { data } = await http.post(url, postData);
        setLoader(false);
        if (data.state) {
            navigate(`/custom-pages/details/${data.page._id}`);
        } else {
            throw(new Error(data.msg));
        }
       } catch (err) {
           throw(new Error(err.message));
       } finally {
            setLoader(false);
       }
        
    }
    return(
    <Page title="Custom pages">
        <Container>
            <Grid container>
                <Grid item xs={12}>
                    <PageEditor initial={{}} handleSave={handleSave} />
                </Grid>
            </Grid>
        </Container>
    </Page>
    )    
}

export default CreateCustomPage;