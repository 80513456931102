import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Delete from '@mui/icons-material/Delete'
import { IconButton, Button } from '@mui/material';

import { useNavigate } from 'react-router-dom';

import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { apiURL } from 'common';
import http from 'common/http';

const CustomPagesTable = ({ pages, mutate }) => {

    const navigate = useNavigate();

    const handleDelete = async (id) => {
        try {
            const url = `${apiURL}/admin/page/${id}`;

            const { data } = await http.delete(url);

            if (data.state) {
                mutate()
            } else {
                throw new Error(data.msg)
            }

        } catch (err) {
            toast.error(err.message);
        }
    }
    return (
        <>

            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                    <TableRow>
                        <TableCell>Page Name</TableCell>
                        <TableCell>Page url</TableCell>
                        <TableCell> Page Title </TableCell>
                        <TableCell >Delete</TableCell>
                        <TableCell align="right"> View more </TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                        {pages.map((page) => (
                            <TableRow
                            key={page._id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{page.name} </TableCell>
                                <TableCell >{page.url}</TableCell>
                                <TableCell >{page.metaTitle}</TableCell>
                                <TableCell> <IconButton onClick={() => handleDelete(page._id)} > <Delete /> </IconButton>  </TableCell>
                                <TableCell  align="right"> <Button onClick={() => navigate(`/custom-pages/details/${page._id}`)}>More</Button> </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default CustomPagesTable;