import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { useParams } from 'react-router-dom'

import Page from 'components/Page'
import LoadingScreen from 'components/loading-screen'
import { usePageDetail } from 'hooks/pages/usePage'

import ProductsTable from '../../products/_components/ProductsTable';

import AddProductToCustomPage from '../_components/AddProductModal';

const PageDetail = () => {

    const { id } = useParams();
    const { page, loading, msg, error, products, mutate } = usePageDetail(id);

    if (loading) {
        return (
            <div sx={{ isolation: 'isolated' }}>
                <LoadingScreen />
            </div>
        )
    }

    return (
        <Page title="Page details">
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <RenderPage error={error} page={page} loading={loading} msg={msg} products={products} mutate={mutate} />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}

const RenderPage = ({ page, loading, error, msg, products, mutate }) => {
    if (loading) {
        return <div>Loading...</div>
    }

    if (error) {
        return (<div>
            <h3>Error loading Data</h3>
            <p>{msg}</p>
        </div>)
    }
    
    return (
        <>
           <Typography component="div" variant="h4" className="primary_color capitalize-first">
            <Grid container direction="row" justifyContent="space-between"  >
                <Grid item>
                    {page.name} 
                </Grid>
                <Grid item>
                    {/* <Button variant="contained" color="primary">Add Product</Button> */}
                    <AddProductToCustomPage page={page} pageId={page._id} mutate={mutate} />
                </Grid>
            </Grid>
          </Typography>

            <Grid container sx={{ mt: 5 }}>

                <Grid item xs={12}>

                    <ProductsTable products={products} />

                </Grid>
            </Grid>
        
        </>
    );
}

export default PageDetail